<template>
  <div>

    <v-row v-if="!$acl.isClinicWithProfessionals()">
      <v-col>
        <v-tabs v-model="tab" background-color="transparent" color="primary">
          <v-tab @click="select()">Movimentações</v-tab>
          <v-tab @click="selectMonthlyBilling()">Mensalidades</v-tab>


        </v-tabs>
      </v-col>
    </v-row>

    <div v-show="tab === 0" class="mt-6">
      <CustomerTransactions :customer="customer" ref="CustomerTransactions" />
    </div>

    <div v-show="tab === 1">
      <CustomerMonthlyBilling :customer="customer" ref="CustomerMonthlyBilling" />
    </div>

  </div>
</template>

<script>
import CustomerTransactions from "@/components/customers/sections/CustomerTransactions.vue";
import CustomerMonthlyBilling from "@/components/customers/sections/CustomerMonthlyBilling.vue";

export default {

  components: {
    CustomerTransactions,
    CustomerMonthlyBilling
  },

  props: {
    customer: {},
  },

  data() {
    return {
      tab: 0,
    };
  },

  computed: {},

  watch: {},

  methods: {
    select() {
      this.$refs.CustomerTransactions.select();
    },
    selectMonthlyBilling() {
      this.$refs.CustomerMonthlyBilling.select();
    }
  }

};
</script>


<style lang="sass">

</style>